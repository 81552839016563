import { Type, ÉµNG_PIPE_DEF, ÉµPipeDef } from '@angular/core';

const NG_PIPE_DEF = ÉµNG_PIPE_DEF as 'Éµpipe';

// Angular doesn't expose publicly `PipeType` but it actually has it.
export interface PipeType<T> extends Type<T> {
  Éµpipe: ÉµPipeDef<T>;
}

export function isPipe<T>(target: any): target is PipeType<T> {
  return !!target[NG_PIPE_DEF];
}
